import React, { Component } from 'react';
import 'firebase/auth';
import 'firebase/database';

class MentorProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            feedback: '',
            submission: '1',
            display: 'feedback'
        };
    }

    componentDidMount() {
        this.props.database.ref("users/").on("value", snapshot => {
            let users = snapshot.val();
            let mentor = users.mentors[this.props.userKey];
            let menteeEmail = mentor.menteeEmail;

            if (menteeEmail == null || menteeEmail.length == 0) {
                this.setState({
                    studentObject: null
                });
            } else {
                let menteeKey = this.getKey(users.students, "email", menteeEmail);

                this.setState({
                    studentObject: users.students[menteeKey]
                });
            }
        });
    }

    getKey(object, fieldName, desiredValue) {
        for (let key in object) {
            if (object[key][fieldName] == desiredValue) {
                return key;
            }
        }
    }

    submitFeedback = (event) => {
        event.preventDefault();
        this.setState({ display: 'submitted' })
        let studentRef = this.props.database.ref('users/students').child(this.props.studentKey)
        studentRef.update({ ['feedback' + this.state.submission]: this.state.feedback })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        // Mentor has not been assigned mentee, show "not assigned" message
        if (this.state.studentObject == null) {
            return (< Notassigned />);
        } else if (this.state.studentObject != null && typeof this.state.studentObject.submission1 === 'undefined') {
            return (< Notsubmitted />);
        } else if (this.state.display === 'submitted') {
            return (< Submittedfeedback />);
        }
        else {  // Mentor has a mentee, fill in information

            let menteeNames = [];
            let menteeSubmissions = [];

            if (this.state.studentObject != null) {

                menteeNames.push(<li key={1}>{this.state.studentObject.applicant}</li>);
                menteeNames.push(<li key={2}>{this.state.studentObject.teammate}</li>);

                if (typeof this.state.studentObject.submission1 != 'undefined') {
                    menteeSubmissions.push(<li key={1}><a target="_blank" href={this.state.studentObject.submission1}>Submission 1</a></li>);
                }
                if (typeof this.state.studentObject.submission2 != 'undefined') {
                    menteeSubmissions.push(<li key={2}><a target="_blank" href={this.state.studentObject.submission2}>Submission 2</a></li>);
                }
                if (typeof this.state.studentObject.submission3 != 'undefined') {
                    menteeSubmissions.push(<li key={3}><a target="_blank" href={this.state.studentObject.submission3}>Submission 3</a></li>);
                }
            }

            return (
                <div className="main-body">
                    <main>
                        <div className="container">

                            <h2>Welcome, Mentor!</h2>
                            <br />
                            <h3>Your mentees are:</h3>
                            <ul>
                                {menteeNames}
                            </ul>
                            <h3>Mentees' Submissions:</h3>
                            <ol>
                                {menteeSubmissions}
                            </ol>
                            <h3>Leave Feedback</h3>
                            <form onSubmit={this.submitFeedback}>
                                <div>
                                    <label htmlFor="checkinForFeedback">Feedback for Check-in:</label>
                                    <label>
                                        <input type="radio" name="submission" value='1'
                                            onChange={this.handleChange} /> 1
                                    </label>
                                    {this.state.studentObject != null && typeof this.state.studentObject.submission2 != 'undefined' &&
                                        <label>
                                            <input type="radio" name="submission" value="2" onChange={this.handleChange} /> 2
                                        </label>
                                    }
                                    {this.state.studentObject != null && typeof this.state.studentObject.submission3 != 'undefined' &&
                                        <label>
                                            <input type="radio" name="submission" value="3" onChange={this.handleChange} /> 3
                                        </label>
                                    }

                                </div>
                                <div>
                                    <label htmlFor="msg">Feedback:</label>
                                    <textarea type="text" value={this.state.feedback} onChange={this.handleChange} id="msg" name="feedback" className="text-input" required="required"></textarea>
                                </div>
                                <div className="button">
                                    <button type="submit" id="leaveFeedbackButton" className="custom-button">Leave Feedback</button>
                                </div>
                            </form>

                            {this.state.display === 'submitted' &&
                                < Submittedfeedback />
                            }

                        </div>
                    </main>
                </div>


            );

        }
    }
}


class Notassigned extends Component {
    render() {
        return (
            <div className="container">
                <h4>You have not been assigned a mentee, please check back later</h4>
            </div>
        );
    }
}

class Notsubmitted extends Component {
    render() {
        return (
            <div className="container">
                <h4>Your mentee has not submitted work yet, please check back later</h4>
            </div>
        );
    }
}

class Submittedfeedback extends Component {
    render() {
        return (
            <div>
                <h4>Thank you for submitting feedback!</h4>
            </div>
        );
    }
}


export default MentorProject;