import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AdminInterface from './AdminInterface';
import StudentProject from './StudentProject';
import MentorProject from './MentorProject';

class MyProject extends Component {

    render() {
        if (this.props.redirect) {
            return <Redirect to='/login' />;
        }
        else if (this.props.userType == "student") {
            return (<StudentProject
                database={this.props.database}
                userKey={this.props.userKey}
            />
            );
        } else if (this.props.userType == "mentor") {
            return (<MentorProject
                database={this.props.database}
                userKey={this.props.userKey}
            />
            );
        } else if (this.props.userType == "admin") {
            return (<AdminInterface database={this.props.database} />);
        } else {
            return (<DefaultMyProject />);
        }
    }
}

class DefaultMyProject extends Component {
    render() {
        return (<div className="main-body">
            <main>
                <div className="container">
                    <h2>Please log in to access this page!</h2>
                    <Link to="/login" className="custom-button">Login</Link>
                </div>
            </main>
        </div>);
    }
}


export default MyProject;