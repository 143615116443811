import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './index.css';
import Top from './Top';
import Bottom from './Bottom';
import MyProject from './MyProject';
import HomePage from './HomePage';
import SubmitQuestion from './SubmitQuestion';
import Login from './Login';
import Apply from './Apply';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';


class App extends Component {

  // State keeps track of whether user is logged in/ logged out
  constructor(props) {
    super(props);
    this.state = {
      scroll: null,
      login: false,
      userType: null,
      userKey: null,
      redirectLogin: false,
      redirectLogout: false,
      errorMessage: '',
      name: '',

    };

    this.db = firebase.database();

    // scrollY value at which sticky navbar sticks
    // the offsetTop vale of the navbar from web console
    this.top = 107;
  }


  handleScroll = () => {
    this.setState({ scroll: window.scrollY });
  }

  componentDidMount() {
    // I used eventListener on window to achieve sticky navbar effect - I don't know how else to do this in React
    window.addEventListener('scroll', this.handleScroll);

    // Detect when user logs in or out
    let authUnregFunc = firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) { //firebaseUser defined: is logged in, redirect from login page to my-project
        this.setState({ login: true, redirectLogin: true, redirectLogout: false });
      } else { //firebaseUser undefined: is not logged in
        // redirect to logout page
        this.setState({
          login: false,
          userType: null,
          userKey: null,
          redirectLogout: true,
          redirectLogin: false
        });
      }
    });
  }



  handleSignIn = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        let currentScope = this;
        // If admin logs in
        if (email == 'admin@uw.edu') {
          // go to admin page
          currentScope.setState({ userType: "admin", name: "admin" });
        } else {
          // Check if login email equals a student email
          let student = currentScope.db.ref('users/students');
          student.once("value").then(snapshot => {
            snapshot.forEach(childSnapshot => {
              let studentObjects = snapshot.val();
              if (studentObjects[childSnapshot.key].email == email) {
                currentScope.setState({
                  userType: "student",
                  name: studentObjects[childSnapshot.key].applicant,
                  email: email,
                  userKey: childSnapshot.key
                });
              }
            })
          })

          // If not admin or student, then show mentor feedback page
          let mentor = firebase.database().ref('users/mentors');
          // Check if login email matches a mentor email
          mentor.once("value").then(snapshot => {
            snapshot.forEach(childSnapshot => {
              let mentorObjects = snapshot.val();
              if (mentorObjects[childSnapshot.key].email == email) {
                currentScope.setState({
                  userType: "mentor",
                  name: mentorObjects[childSnapshot.key].name,
                  userKey: childSnapshot.key
                });
              }
            })
          });
        }
        this.setState({ errorMessage: '' });
      }).catch(err => this.setState({ errorMessage: err.message }));
  }

  // When logged out, still be able to navigate to home, submit question, and apply
  handleLinks = () => {
    this.setState({ redirectLogout: false })
  }

  // Source of stickynavbar React implementation, modified to suit needs of app: https://mattgaskey.com/blog/sticky-nav-in-react/
  render() {
    return (

      <div>
        <Top
          login={this.state.login}
          name={this.state.name}
          handleLinks={this.handleLinks}
          navClass={this.state.scroll > this.top ? "navbar-fixed" : ""}
        />
        <div className={this.state.scroll > this.top ? "increased-margin" : ""}>
          <Switch>
            <Route exact path='/' render={() => (
              <HomePage redirect={this.state.redirectLogout} />
            )}
            />
            <Route path='/my-project' render={() => (
              <MyProject
                redirect={this.state.redirectLogout}
                database={this.db} 
                userKey={this.state.userKey}
                userType={this.state.userType}
              />
            )} />
            <Route
              path='/submit-question'
              render={() => (
                <SubmitQuestion redirect={this.state.redirectLogout}
                />
              )}
            />
            <Route path='/login' render={() => (
              // removed redirect redirect={this.state.redirect}
              <Login login={this.handleSignIn} redirect={this.state.redirectLogin} error={this.state.errorMessage} />
            )} />
            <Route path='/apply' render={() => (<Apply database={this.db} redirect={this.state.redirectLogout} />)} />
          </Switch>
          <Bottom />
        </div>
      </div>
    );
  }
}

export default App;
