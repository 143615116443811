import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database'

var config = {
    apiKey: "AIzaSyCBq8G24Aye6bOeN5MK0l2cOsqZXLfgZb4",
    authDomain: "stage-4-final-project.firebaseapp.com",
    databaseURL: "https://stage-4-final-project.firebaseio.com",
    projectId: "stage-4-final-project",
    storageBucket: "stage-4-final-project.appspot.com",
    messagingSenderId: "656723868967"
};
firebase.initializeApp(config);

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
