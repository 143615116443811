import React, { Component } from 'react';
import presentations from "./img/three-presentations.png"
import caseStudy from "./img/select-crop.png";
import mentor from "./img/bi-weekly-crop.png";
import showcase from "./img/tech-showcase-crop.png";
import { Link, Redirect } from 'react-router-dom';

class HomePage extends Component {
   
    render() {
        if(this.props.redirect) {
            return <Redirect to='/login' />;
        }
        return (
            <div className="main-body" id="body-div">
                <main>
                    <div className="container">
                        <h2>become industry ready @ THE <span className="orange">TECH</span> PROJECT</h2>
                        <p className="heading-detail">data science. ux. web dev.</p>
                        <p>You've done enough homework. It's time to start applying your skills to the real world.
                            You've gotten your feet wet through your classes.
                    It's time to roll up your sleeves, use that knowledge and apply for the Tech Project.</p>
                        <div className="apply-parent"><Link to='/apply'>
                            <div className="custom-button big-apply" role="button">Apply Now</div>
                        </Link>
                        </div>
                        <img src={presentations} className="big-image" alt="presentations graphic" />
                        <div className="container">
                            <h3>How does it work?</h3>
                            <div className="row">
                                <div className="col-lg-6">
                                    <img className="d-none d-sm-block" src={caseStudy} alt="case study graphic" />
                                </div>
                                <div className="col-lg-6 text-block">
                                    <h4>
                                        <span className="brightblue">Select a Case Study Project</span>
                                    </h4>
                                    <p>After getting selected for the program, you will get an opportunity to select a project
                                from a curated list of problem statements set to industry standards.</p>
                                    <p>Through the course of the 6 weeks, you will complete a weekly checkin-in with Dubstech
                                        with the required deliverables after which you will present your work at our Winter
                                Tech Showcase event.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <img className="d-none d-sm-block" src={mentor}
                                        alt="mentor interaction graphic" />
                                </div>
                                <div className="col-lg-6">
                                    <h4><span className="brightblue">Bi-Weekly Mentor Interactions &amp; Learning Resources</span>
                                    </h4>
                                    <p>Our senior student mentors will be available to you every two weeks to get advice from
                                and to talk regarding problems you are stumbling upon in your development cycle.</p>
                                    <p>To ensure you are comfortable with the project, Dubstech has created a curated list of
                                video learning resources to help improve your skillset.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <img className="d-none d-sm-block" src={showcase} alt="tech showcase graphic" />
                                </div>
                                <div className="col-lg-6">
                                    <h4><span className="brightblue">Tech Showcase Presentation</span></h4>
                                    <p>At the culmination of your project you will make a final presentation of your work at
                                        our Spring Tech Showcase in the presence of all industry professionals and all the
                                participating teams.</p>
                                </div>
                            </div>
                        </div>
                        <div className="apply-parent"><Link to='/apply'>
                            <div className="custom-button big-apply" role="button">Apply Now</div>
                        </Link></div>
                    </div>
                </main>
            </div>
        );

    }


}

export default HomePage;