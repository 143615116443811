import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class SubmitQuestion extends Component {

    render() {
        if(this.props.redirect) {
            return <Redirect to='/login' />;
        }
        return (
            <div>
                <div className="main-body" id="body-div">
                    <main>
                        <div className="container">
                            <h2>Have a Question or Concern<span className="orange">?</span></h2>
                            <form action="#" method="post">
                                <div>
                                    <label htmlFor="subject">Subject:</label>
                                    <input type="text" id="subject" name="subject_field" required="required" className="text-input" />
                                </div>
                                <div>
                                    <label htmlFor="msg">Message:</label>
                                    <textarea id="msg" name="user_message" required="required" className="text-input"></textarea>
                                </div>
                                <div className="button">
                                    <button type="submit" className="custom-button">Send message</button>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        );

    }


}

export default SubmitQuestion;