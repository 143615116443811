import React, { Component } from 'react';

class Bottom extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <small className="copyright">
                        &copy; Copyright 2019, Dubstech
                </small>
                </div>
            </footer>
        );
    }
}

export default Bottom;