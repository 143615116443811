import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Redirect } from 'react-router-dom';

class Apply extends Component {

    constructor(props) {
        super(props);
        this.state = {
        
            display: 'Application'
        }
    }

    // Once information is submitted, state and database is updated, account is made
    onSubmit = (event) => {
        event.preventDefault();
        this.setState({
            display: 'Applied'
        });
        let students = this.props.database.ref('users/students');
        students.push({
            applicant: event.target[0].value,
            email: event.target[1].value,
            teammate: event.target[2].value,
            resume: event.target[3].value,
            password: event.target[4].value,
            statement: event.target[5].value,
            userType: 'student'
        });

        firebase.auth().createUserWithEmailAndPassword(event.target[1].value, event.target[4].value)
    }

    render() {
        if(this.props.redirect) {
            return <Redirect to='/login' />;
        }
        return (
            <div>
                <div className="main-body" id="body-div">
                    <main>
                        <div className="container">
                            <h2>Application for THE <span className="orange">TECH</span> PROJECT</h2>
                            <p className="heading-detail">Applications close on <time dateTime="2019-3-14 23:59">March 14, 2019 at
                        11:59pm</time></p>

                            <Process />
                            {this.state.display == 'Application' &&
                                <Form button={this.onSubmit} state={this.state} display={this.state.displayApplication} />
                            }
                            {this.state.display == 'Applied' &&
                                <Applied />
                            }
                        </div>
                    </main>
                </div>
            </div>
        );

    }
}

class Process extends Component {
    render() {
        return (
            <ol id="application-process" >
                <li>
                    <h3>Check your Skillset</h3>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <h4 className="brightblue">Data Science</h4>
                                <p>Python<br />or R</p>
                            </div>
                            <div className="col-lg-4">
                                <h4 className="brightblue">UX Design</h4>
                                <p>Sketch<br />or Figma<br />or Adobe XD</p>
                            </div>
                            <div className="col-lg-4">
                                <h4 className="brightblue">Web Dev</h4>
                                <p>Flexbox<br />+ JavaScript</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <h3>Find a Teammate</h3>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <p>Working on a project such as this alone is a challenging task. Hence we
                                encourage you to find 1 team-mate you are comfortable with to work with you.</p>
                            </div>
                            <div className="col-lg-6">
                                <img className="d-none d-sm-block" src="img/find-teammate-crop.png" alt="find teammate graphic" />
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <h3>Apply Below</h3>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <p>Submit an application with your resume and a short statement on why you wish to
                                    participate in the Tech Project. Your email and password will be your login
                                credentials.</p>
                            </div>
                            <div className="col-lg-6">
                                <img className="d-none d-sm-block" src="img/apply-online-crop.png" alt="apply online graphic" />
                            </div>
                        </div>
                    </div>
                </li>
            </ol>

        );
    }
}



// where are input value props coming from and where are they going?
class Form extends Component {
    render() {
        return (
            <form onSubmit={this.props.button} >
                <h3>Personal Information</h3>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="applicant-name">Full Name:</label>
                            <input type="text" id="applicant-name" name="applicant" className="text-input"
                                required="required"></input>
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="applicant-email">Email:</label>
                            <input type="text" id="applicant-email" name="email" required="required"
                                className="text-input"></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="teammate-name">Teammate Name:</label>
                            <input type="text" id="teammate-name" name="teammate" className="text-input"
                                required="required" />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="resume">Resume Link:</label>
                            <input type="text" id="resume" name="resume" required="required"
                                className="text-input"></input>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="password">Password:</label>
                        <input type="password"  pattern=".{6,}" id="password" name="password" required="required"
                            className="text-input"></input>
                    </div>
                    <div>
                        <label htmlFor="statement">Statement of Interest:</label>
                        <textarea id="statement"  name="statement" required="required" className="text-input"></textarea>
                    </div>
                    <div className="button">
                        <button type="submit" className="custom-button">Submit Application</button>
                    </div>
                </div>

            </form>
        );
    }
}

class Applied extends Component {
    render() {
        return (
            <div>
                <h3>Thank you for applying!</h3>
                <h4>An account has been made for you with the inputted email and password. A project and mentor will be assigned to you soon.</h4>
            </div>
        )
    }
}

export default Apply;