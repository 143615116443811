import React, { Component } from 'react';
import { Table } from 'reactstrap';

class StudentProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openFeedback: [false, false, false],
            displayFileUpload: [false, false, false],
            submitButtonText: ["Submit Work", "Submit Work", "Submit Work"],
            feedbackButtonText: ["See Feedback", "See Feedback", "See Feedback"],
            currentStudent: null
        };
    }

    componentDidMount() {
        this.props.database.ref("users/students/" + this.props.userKey).on("value", snapshot => {
            this.setState({
                currentStudent: snapshot.val()
            });
        });
    }

    submitWork = (checkin) => {
        let index = checkin - 1;
        let currentDisplayFileUpload = this.state.displayFileUpload.slice(0);
        let currentSubmitButtonText = this.state.submitButtonText.slice(0);
        if (!this.state.displayFileUpload[index]) {
            currentDisplayFileUpload[index] = true;
            currentSubmitButtonText[index] = "Close Form";
        } else {
            currentDisplayFileUpload[index] = false;
            currentSubmitButtonText[index] = "Submit Work";
        }
        this.setState({
            displayFileUpload: currentDisplayFileUpload,
            submitButtonText: currentSubmitButtonText
        });
    }

    submitFileLink = (checkin, submissionLink) => {
        let updateObject;
        if (checkin == 1) {
            updateObject = {
                submission1: submissionLink
            }
        } else if (checkin == 2) {
            updateObject = {
                submission2: submissionLink
            }
        } else if (checkin == 3) {
            updateObject = {
                submission3: submissionLink
            }
        }
        this.props.database.ref("/users/students/").child(this.props.userKey).update(updateObject);
        this.submitWork(checkin);
    }

    viewFeedback = (checkin) => {
        let currentOpenFeedback = this.state.openFeedback.slice(0);
        let currentFeedbackButtonText = this.state.feedbackButtonText.slice(0);
        if (!this.state.openFeedback[checkin - 1]) {
            currentOpenFeedback[checkin - 1] = true;
            currentFeedbackButtonText[checkin - 1] = "Hide Feedback";
        } else {
            currentOpenFeedback[checkin - 1] = false;
            currentFeedbackButtonText[checkin - 1] = "See Feedback";
        }
        this.setState({
            openFeedback: currentOpenFeedback,
            feedbackButtonText: currentFeedbackButtonText
        });
    }

    render() {
        return (
            <div className="main-body">
                <main>
                    <div className="container">
                        <ProjectInfo
                            student={this.state.currentStudent}
                            database={this.props.database}
                            submitWork={this.submitWork}
                            viewFeedback={this.viewFeedback}
                            submitButtonText={this.state.submitButtonText}
                            feedbackButtonText={this.state.feedbackButtonText}
                        />
                        <UploadFileDiv
                            displayFileUpload={this.state.displayFileUpload}
                            submitFileLink={this.submitFileLink}
                        />
                        <ViewFeedbackDiv
                            student={this.state.currentStudent}
                            openFeedback={this.state.openFeedback}
                        />
                    </div>
                </main>
            </div>
        );
    }
}

class ProjectInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mentors: null
        };
    }

    getKey(object, fieldName, desiredValue) {
        for (let key in object) {
            if (object[key][fieldName] == desiredValue) {
                return key;
            }
        }
    }

    componentDidMount() {
        this.props.database.ref("users/mentors/").on("value", snapshot => {
            this.setState({
                mentors: snapshot.val()
            });
        });
    }

    render() {
        let checkinRows = [];
        for (let i = 0; i < 3; i++) {
            checkinRows.push(
                <CheckinRow
                    key={i}
                    checkin={i + 1}
                    student={this.props.student}
                    submitWork={this.props.submitWork}
                    viewFeedback={this.props.viewFeedback}
                    submitButtonText={this.props.submitButtonText[i]}
                    feedbackButtonText={this.props.feedbackButtonText[i]} />
            )
        }

        let projectName = "unassigned";
        let projectType = "unassigned";
        let problemStatement = "unassigned";
        let mentor = "unassigned";
        let mentorEmail = "unassigned";
        let latestCheckin = 0;
        if (this.props.student != null && this.state.mentors != null) {
            if (this.props.student.mentorEmail != null && this.props.student.mentorEmail.length > 0) {
                mentorEmail = this.props.student.mentorEmail;
                let mentorKey = this.getKey(this.state.mentors, "email", mentorEmail);
                mentor = this.state.mentors[mentorKey].name;
            }
            if (this.props.student.projectName != null && this.props.student.projectName.length > 0) {
                projectName = this.props.student.projectName;
            }
            if (this.props.student.projectType != null && this.props.student.projectType.length > 0) {
                projectType = this.props.student.projectType;
            }
            if (this.props.student.problemStatement != null && this.props.student.problemStatement.length > 0) {
                problemStatement = this.props.student.problemStatement;
            }

            if (this.props.student.submission3 != null && this.props.student.submission3.length > 0) {
                latestCheckin = 3;
            } else if (this.props.student.submission2 != null && this.props.student.submission2.length > 0) {
                latestCheckin = 2;
            } else if (this.props.student.submission1 != null && this.props.student.submission1.length > 0) {
                latestCheckin = 1;
            }

        }

        return (
            <div>
                <h2><span className="orange">Project:</span> {projectName}</h2>
                <p className="heading-detail">{projectType + "."}</p>
                <h3>Problem Statement</h3>
                <p>{problemStatement}</p>
                <h3>Mentor:<span className="orange"> {mentor}</span></h3>
                <address>
                    <a href={"mailto:" + mentorEmail}>{mentorEmail}</a><br />
                </address>
                <div className="increased-margin">
                    <h3>Project Progress: <span id="progressSpan" className="brightblue">{30 * latestCheckin + "%"}</span></h3>
                    <Table className="my-project-table">
                        <tbody>
                            {checkinRows}
                        </tbody>
                    </Table>
                </div>

            </div>
        );
    }
}


class CheckinRow extends Component {
    render() {
        return (
            <tr>
                <td>Check-in {this.props.checkin}</td>
                <td>
                    <SubmitButton
                        student={this.props.student}
                        submitWork={this.props.submitWork}
                        checkin={this.props.checkin}
                        text={this.props.submitButtonText}
                    />
                </td>
                <td>
                    <ViewSubmissionButton
                        student={this.props.student}
                        checkin={this.props.checkin}
                    />
                </td>
                <td>
                    <FeedbackButton
                        student={this.props.student}
                        viewFeedback={this.props.viewFeedback}
                        checkin={this.props.checkin}
                        text={this.props.feedbackButtonText}
                    />
                </td>
            </tr>
        );
    }
}

// make this work for all submissions, not just submission 1
class ViewSubmissionButton extends Component {
    render() {
        let checkin = this.props.checkin;
        let visible =
            this.props.student != null
            && this.props.student[`submission${checkin}`] != null
            && this.props.student[`submission${checkin}`].length > 0;
        if (visible) {
            return (<a href={this.props.student[`submission${checkin}`]} target="_blank" ><button className="custom-button">View Submission</button></a>);
        } else {
            return (
                <button className="custom-button disabled-button">Not submitted</button>
            );
        }
    }
}

class SubmitButton extends Component {

    submitWork = (event) => {
        event.preventDefault();
        this.props.submitWork(this.props.checkin);
    }

    render() {
        var buttonClassName = " disabled-button";
        let student = this.props.student;
        let checkin = this.props.checkin;

        if (student != null) {
            if (checkin == 1) {
                buttonClassName = "";
            } else if (checkin == 2) {
                if (student.submission1 != null && student.submission1.length > 0) {
                    buttonClassName = "";
                }
            } else if (checkin == 3) {
                if (student.submission2 != null && student.submission2.length > 0) {
                    buttonClassName = "";
                }
            }
        }
        return (
            <button onClick={this.submitWork} checkin={this.props.checkin} aria-controls="viewFeedbackDiv"
                className={"custom-button" + buttonClassName}>{this.props.text}</button>
        );
    }
}

class FeedbackButton extends Component {
    
    viewFeedback = (event) => {
        event.preventDefault();
        this.props.viewFeedback(this.props.checkin);
    }
    
    render() {
        let buttonClassName = " disabled-button"
        let student = this.props.student;
        // null or empty feedback is assumed to be something not worth being seen
        if (student != null && student[`feedback${this.props.checkin}`] != null && student[`feedback${this.props.checkin}`].length > 0) {
            buttonClassName = "";
        }

        return (
            <button onClick={this.viewFeedback} checkin={this.props.checkin} aria-controls="viewFeedbackDiv"
                className={"custom-button" + buttonClassName}>{this.props.text}</button>
        );

    }
}


class UploadFileDiv extends Component {
    render() {
        let singleUploads = [];
        for (let i = 0; i < 3; i++) {
            singleUploads.push(<UploadSingleFile key={i} checkin={i + 1} display={this.props.displayFileUpload[i]} submitFileLink={this.props.submitFileLink} />)
        }
        return (
            <div aria-live="polite" id="uploadFileDiv">{singleUploads}</div>
        );
    }
}

class UploadSingleFile extends Component {

    submitFile = (event) => {
        event.preventDefault();
        this.props.submitFileLink(this.props.checkin, event.target[0].value);
    }

    render() {
        if (this.props.display) {
            return (
                <div><h3>Upload Submission for Check-in {this.props.checkin}</h3>
                    <form onSubmit={this.submitFile}>
                        <div>
                            <label htmlFor="file-upload">Submission Link:</label>
                            
                            <input type="text" id="file-upload" name="file-field" required="required"
                                className="text-input" />
                        </div>
                        <div>
                        <p>Please include header in link (e.g. https://)</p>
                        </div>
                        <div>
                            <button id="submitLinkButton" type="submit" checkin={this.props.checkin} className="custom-button">Submit Link</button></div>
                    </form></div>
            );
        } else {
            return (<div></div>);
        }
    }
}

class ViewFeedbackDiv extends Component {
    render() {
        let feedbacks = [];
        for (let i = 0; i < 3; i++) {
            if (this.props.openFeedback[i]) {
                feedbacks.push(<IndividualFeedback key={i} checkin={i + 1} student={this.props.student} />);
            }

        }
        return (
            <div aria-live="polite" id="viewFeedbackDiv">
                {feedbacks}
            </div>
        );
    }
}

class IndividualFeedback extends Component {
    render() {
        let feedback = this.props.student[`feedback${this.props.checkin}`];
        if (feedback == null || feedback.length == 0) {
            return (
                <div>
                    <h3>Feedback for <span className="brightblue">Check-in {this.props.checkin}</span></h3>
                    <p>There is no submitted feedback for this check-in!</p>
                </div>
            );
        } else {
            return (
                <div>
                    <h3>Feedback for <span className="brightblue">Check-in {this.props.checkin}</span></h3>
                    <p>{this.props.student[`feedback${this.props.checkin}`]}</p>
                </div>
            );
        }
    }
}

export default StudentProject;