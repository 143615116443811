import React, { Component } from 'react';
import { Table } from 'reactstrap';

class ViewAllMentees extends Component {

    render() {

        let mentorRows = [];
        let menteeRows = [];

        for (let key in this.props.students) {
            menteeRows.push(<SingleMenteeRow key={key} studentKey={key} student={this.props.students[key]} viewToggle={this.props.viewToggle} />);
        }

        for (let key in this.props.mentors) {
            mentorRows.push(<SingleMentorRow key={key} mentor={this.props.mentors[key]} />);
        }

        return (
            <div className="main-body">
                <main>
                    <div className="container">
                        <h2>Welcome, Admin!</h2>
                        <br />
                        <div>
                            <h3>Mentors</h3>
                            <Table className="my-project-table">
                                <thead>
                                    <tr>
                                        <td>Mentor Login</td>
                                        <td>Name</td>
                                        <td>Mentees Assigned?</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mentorRows}
                                </tbody>
                            </Table>
                        </div>
                        <div className="increased-margin">
                            <h3>Mentee Assignments</h3>
                            <Table className="my-project-table">
                                <thead>
                                    <tr>
                                        <td>Mentee Login</td>
                                        <td>Teammate 1</td>
                                        <td>Teammate 2</td>
                                        <td>Mentor Login</td>
                                        <td>Edit Assignments</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {menteeRows}
                                </tbody>
                            </Table>
                        </div>
                    </div>

                </main>
            </div>
        );
    }
}

class SingleMenteeRow extends Component {

    viewToggle = (event) => {
        event.preventDefault();
        this.props.viewToggle(this.props.studentKey);
    }

    render() {
        return (<tr>
            <SingleTableData value={this.props.student.email} />
            <SingleTableData value={this.props.student.applicant} />
            <SingleTableData value={this.props.student.teammate} />
            <SingleTableData value={this.props.student.mentorEmail} />
            <td><button className="custom-button" onClick={this.viewToggle}>Edit</button></td>
        </tr>);
    }
}

class SingleTableData extends Component {
    render() {
        if (this.props.value == null || this.props.value.length == 0) {
            return (<td>unassigned</td>);
        } else {
            return (<td>{this.props.value}</td>);
        }
    }
}

class SingleMentorRow extends Component {
    render() {
        return (
            <tr>
                <td>{this.props.mentor.email}</td>
                <td>{this.props.mentor.name}</td>
                <SingleTableData value={this.props.mentor.menteeEmail} />
            </tr>
        );
    }
}

export default ViewAllMentees;