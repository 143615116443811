import React, { Component } from 'react';
class ViewIndividualMentee extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            mentors: null
        }
    }

    componentDidMount() {
        this.props.database.ref("users/mentors/").on("value", snapshot => {
            this.setState({
                mentors: snapshot.val()
            });
        });
    }

    getKey(object, fieldName, desiredValue) {
        for (let key in object) {
            if (object[key][fieldName] == desiredValue) {
                return key;
            }
        }
    }

    getValueToUpdate(oldValue, newValue) {
        if ((newValue == null || newValue.length == 0) && (oldValue == null || oldValue.length == 0)) {
            return "";
        } else if (newValue == null || newValue.length == 0) {
            return oldValue;
        } else {
            return newValue;
        }
    }

    isValidMentorEmail(email) {
        if (email == null) {
            return false;
        }
        if (email.length == 0) {
            return false;
        }

        for (let key in this.props.mentors) {
            if (this.props.mentors[key].email == email) {
                return true;
            }
        }
        return false;
    }

    isValidStudentEmail(email) {
        if (email == null) {
            return false;
        }
        if (email.length == 0) {
            return false;
        }

        for (let key in this.props.students) {
            if (this.props.students[key].email == email) {
                return true;
            }
        }
        return false;
    }

    displayError(error) {
        this.setState({
            errors: this.state.errors.concat(error)
        })
    }

    submitChanges = (event) => {
        event.preventDefault();

        // clear errors and messages
        this.setState({
            errors: [""]
        });

        let myKey = this.props.studentKey;
        let myStudent = this.props.students[myKey];
        let currentMentorEmail = myStudent.mentorEmail;
        let newMentorEmail = this.getValueToUpdate(myStudent.mentorEmail, event.target[0].value);

        let studentPostData = {
            projectName: this.getValueToUpdate(myStudent.projectName, event.target[1].value),
            projectType: this.getValueToUpdate(myStudent.projectType, event.target[2].value),
            projectDescription: this.getValueToUpdate(myStudent.projectDescription, event.target[3].value)
        };

        this.updateDatabase("/users/students/", myKey, studentPostData);

        // proceed only if new mentor email is valid and different from previous mentor email
        if (this.isValidMentorEmail(newMentorEmail) && newMentorEmail != currentMentorEmail) {

            let newMentorKey = this.getKey(this.props.mentors, "email", newMentorEmail);

            // set the mentorEmail of the current student to be the new mentorEmail

            let newMentorObject = { mentorEmail: newMentorEmail };

            this.updateDatabase("/users/students/", myKey, newMentorObject);

            // set the menteeEmail of the new mentor to be the currently selected student team email
            this.updateDatabase("/users/mentors/", newMentorKey, { menteeEmail: myStudent.email })

            // if the newly assigned mentor is already assigned a mentee, set that mentee's mentor to ""
            let removedMenteeEmail = this.props.mentors[newMentorKey].menteeEmail;
            if (this.isValidStudentEmail(removedMenteeEmail)) {
                let removedMenteeKey = this.getKey(this.props.students, "email", removedMenteeEmail);

                // makes app more robust to corrupt database
                if (removedMenteeKey != myKey) {
                    this.updateDatabase("/users/students/", removedMenteeKey, { mentorEmail: "" });
                }

            }
            // update currentMentor (if valid) and the currentMentor's previous mentees (if they exist)
            if (this.isValidMentorEmail(currentMentorEmail)) {
                let currentMentorKey = this.getKey(this.props.mentors, "email", currentMentorEmail);

                // if the currentMentor email is valid, update its menteeEmail to be ""
                this.updateDatabase("/users/mentors/", currentMentorKey, { menteeEmail: "" });

                // if the currentMentor had a valid mentee assigned
                let currentMentorPreviousMenteeEmail = this.props.mentors[currentMentorKey].menteeEmail;
                if (this.isValidStudentEmail(currentMentorPreviousMenteeEmail)) {
                    let currentMentorPreviousMenteeKey = this.getKey(this.props.students, "email", currentMentorPreviousMenteeEmail);
                    // set the mentorEmail of that mentee to be ""

                    // this check makes the app more robust to inconsistencies in the database
                    if (currentMentorPreviousMenteeKey != myKey) {
                        this.updateDatabase("/users/students/", currentMentorPreviousMenteeKey, { mentorEmail: "" });
                    }

                }
            }
        } else if (event.target[0].value.length > 0) {
            if (event.target[0].value == currentMentorEmail) {
                this.displayError("The email of the mentor you entered is already assigned to this mentee account!")
            } else {
                this.displayError("The email of the mentor you entered is not recognized!");
            }
        }

        this.clearInputs(event.target);
    }

    clearInputs(myEventTarget) {
        for (let i = 0; i < myEventTarget.length; i++) {
            myEventTarget[i].value = "";
        }
    }
    updateDatabase(ref, childKey, updateObject) {
        this.props.database.ref(ref).child(childKey).update(updateObject, error => {
            console.log(error);
        });
    }

    render() {

        // placeholder text
        let menteeLogin = this.props.students[this.props.studentKey].email;
        let teammate1 = this.props.students[this.props.studentKey].applicant;
        let teammate2 = this.getValueToUpdate("Teammate not assigned", this.props.students[this.props.studentKey].teammate);
        let mentor = this.getValueToUpdate("Mentor not assigned", this.props.students[this.props.studentKey].mentorEmail);
        let projectName = this.getValueToUpdate("Project name not assigned", this.props.students[this.props.studentKey].projectName);
        let projectType = this.getValueToUpdate("Project description not assigned", this.props.students[this.props.studentKey].projectType);
        let projectDescription = this.getValueToUpdate("Project Description not assigned", this.props.students[this.props.studentKey].projectDescription);
        let resumeLink = this.getValueToUpdate("No resume link!", this.props.students[this.props.studentKey].resume);
        let statementOfInterest = this.getValueToUpdate("No statement of interest!", this.props.students[this.props.studentKey].statement);
        let displayedErrors = this.state.errors.map((error, index) => {
            return <p key={index}>{error}</p>
        });

        return (
            <div className="main-body">
                <main>
                    <div className="container">
                        <h2>Edit Mentee Settings</h2>
                        <p>Current mentee login: <span className="orange">{menteeLogin}</span></p>
                        <p>Teammate 1: <span className="orange">{teammate1}</span></p>
                        <p>Teammate 2: <span className="orange">{teammate2}</span></p>
                        <p>
                            Mentor: <MentorField
                                mentors={this.state.mentors}
                                mentorEmail={mentor}
                                className="orange"
                                getKey={this.getKey}
                            />
                        </p>
                        <p>Resume Link: <span className="orange"><a href={resumeLink}>{resumeLink}</a></span></p>
                        <p>Statement of Interest: </p>
                        <p>{statementOfInterest}</p>
                        <form onSubmit={this.submitChanges}>
                            <div>
                                <label htmlFor="projMentor">Assigned Mentor Email:</label>
                                <input type="text" id="projMentor" name="projMentor_field" placeholder={mentor} className="text-input" />
                            </div>
                            <div>
                                <label htmlFor="projTitle">Project Name:</label>
                                <input type="text" id="projTitle" name="projTitle_field" placeholder={projectName} className="text-input" />
                            </div>
                            <div>
                                <label htmlFor="projType">Project Type:</label>
                                <input type="text" id="projType" name="projType_field" placeholder={projectType} className="text-input" />
                            </div>
                            <div>
                                <label htmlFor="projDescription">Project Description:</label>
                                <textarea id="projDescription" name="projDescription_field" placeholder={projectDescription} className="text-input"></textarea>
                            </div>
                            <div className="button">
                                <button type="submit" className="custom-button" >Submit Changes</button>
                            </div>
                        </form>
                        <div>
                            {displayedErrors}
                        </div>
                        <br />
                        <button className="custom-button" onClick={this.props.viewToggle} >View all mentees</button>
                    </div>
                </main>

            </div>);
    }
}

class MentorField extends Component {
    render() {
        if (this.props.mentors != null && this.props.mentorEmail != "Mentor not assigned") {
            return (<span className={this.props.className}>{this.props.mentors[this.props.getKey(this.props.mentors, "email", this.props.mentorEmail)].name}</span>);
        } else {
            return (<span className={this.props.className}>Mentor not assigned</span>);
        }
    }
}

export default ViewIndividualMentee;