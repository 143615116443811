import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavLink,
    NavItem
} from 'reactstrap';

import logo from "./img/logo-dark-bg.png";

class Top extends Component {
    // When student logs out, redirect to login
    handleSignOut = () => {
        firebase.auth().signOut()
            .catch(err => this.setState({ errorMessage: err.message }));
    }

    render() {
        return (
            <div>
                <DubsTechHeader />
                <MainNavBar login={this.props.login} navClass={this.props.navClass} logout={this.handleSignOut} name={this.props.name} handleLinks={this.props.handleLinks} />
            </div>
        );
    }
}

class DubsTechHeader extends Component {
    render() {
        return (
            <header>
                <div className="main-logo">
                    <img src={logo} alt="Dubstech logo" />
                    <h1>THE <span className="orange">TECH</span> PROJECT</h1>
                </div>
            </header>
        );
    }
}


class MainNavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }
    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }



    render() {
        return (
            <Navbar className={"main-nav-bar " + this.props.navClass} expand="md">
                <NavbarToggler onClick={this.toggle} className="toggler-fix" />
                <div className="container">
                    <Collapse isOpen={this.state.isOpen} navbar>

                        <Nav navbar>
                            <NavItem>
                                <NavLink tag={Link} to="/" onClick={this.props.handleLinks} className="active">Home</NavLink>
                            </NavItem>
                            


                            <NavItem>
                                <NavLink tag={Link} to="/submit-question" onClick={this.props.handleLinks} className="active">Submit Question</NavLink>
                            </NavItem>


                            <NavItem>
                                <NavLink tag={Link} to="/apply" onClick={this.props.handleLinks} className="active">Apply</NavLink>
                            </NavItem>
                            <LinkForUser visible={firebase.auth().currentUser != null} whereTo="/my-project" title="My Project" />
                        </Nav>
                        <Nav className="ml-auto" navbar>
                            {this.props.login === true &&
                                <Nav>
                                    <NavItem className="navbar-text">
                                        Hello {this.props.name}
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/login" className="active" onClick={this.props.logout}>Logout</NavLink>
                                    </NavItem>

                                </Nav>
                            }

                            {this.props.login === false &&

                                <NavItem>
                                    <NavLink tag={Link} to="/login" className="active">Login</NavLink>
                                </NavItem>
                            }
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        );
    }
}

class LinkForUser extends Component {
    render() {
        if (this.props.visible) {
            return (
                <NavItem>
                    <NavLink tag={Link} to={this.props.whereTo} className="active">{this.props.title}</NavLink>
                </NavItem>
            );
        } else {
            return (<div></div>);
        }

    }
}



export default Top;