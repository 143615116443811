import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { Link, Redirect } from 'react-router-dom';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    }

    // componentDidMount() {
    
    //     // Detect when user logs in or out
    //     let authUnregFunc = firebase.auth().onAuthStateChanged((firebaseUser) => {
    //       if (firebaseUser) { //firebaseUser defined: is logged in
    //         this.setState({ login: true });
    //       } else { //firebaseUser undefined: is not logged in
    //         // redirect was being set to true, now is removed
    //         this.setState({
    //           login: false
    //         });
    //       }
    //     });
    //   }


    // Updates email and password of state
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    login = (event) => {
        event.preventDefault();
        this.props.login(this.state.email, this.state.password)
       
    }

    render() {
        if(this.props.redirect) {
            return <Redirect to='/my-project' />;
        }
        return (
            <div>
                <main>
                    <div className="container">
                        <div id="loginForm">
                            <h2>Login</h2>
                            <div>
                                <p>{this.props.error}</p>
                            </div>
                            <form action="#" method="post">
                                <div>
                                    <label htmlFor="username">Email/Username:</label>
                                    <input type="text" id="username" value={this.state.email} onChange={this.onChange} className="text-input" name="email" required="required" />
                                </div>
                                <div>
                                    <label htmlFor="password">Password:</label>
                                    <input type="password" id="password" value={this.state.password} onChange={this.onChange} name="password" required="required"
                                        className="text-input"></input>
                                </div>
                                <div className="button">
                                    <button id="loginFormButton" className="custom-button" onClick={this.login} >Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}



export default Login;