import React, { Component } from 'react';
import ViewIndividualMentee from './ViewIndividualMentee';
import ViewAllMentees from './ViewAllMentees';

class AdminInterface extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "table",
            displayedStudentKey: null,
            students: [],
            mentors: []
        }
    }

    viewToggle = (currentStudentKey) => {
        if (this.state.view == "table") {
            this.setState({
                view: "individual",
                displayedStudentKey: currentStudentKey
            });
        } else if (this.state.view == "individual") {
            this.setState({
                view: "table",
                displayedStudentKey: null
            });
        }
    }

    componentDidMount() {
        this.props.database.ref("users/mentors").on("value", snapshot => {
            this.setState({
                mentors: snapshot.val()
            });
        });

        this.props.database.ref("users/students").on("value", snapshot => {
            this.setState({
                students: snapshot.val()
            });
        });
    }

    render() {
        if (this.state.view == "table") {
            return (<ViewAllMentees
                database={this.props.database}
                students={this.state.students}
                mentors={this.state.mentors}
                viewToggle={this.viewToggle}
            />);
        } else if (this.state.view == "individual") {
            return (<ViewIndividualMentee
                database={this.props.database}
                students={this.state.students}
                mentors={this.state.mentors}
                viewToggle={this.viewToggle}
                studentKey={this.state.displayedStudentKey}
            />);
        }

    }
}



export default AdminInterface;